<template>
    <div class="mobile-header">
        <div class="header-content">
            <div class="logo">
                <img src="../../assets/images/pc/logo.png" />
            </div>
<!--            <div class="content">-->
<!--                <div class="nav">-->
<!--                    <div class="nav-item">-->
<!--                        <span :class="{active: active === 0}"><a href="#bolwup" @click="active = 0">{{$t('header1')}}</a></span>-->
<!--                    </div>-->
<!--                    <div class="nav-item">-->
<!--                        <span :class="{active: active === 1}"><a href="#tokenomics" @click="active = 1">{{$t('header2')}}</a></span>-->
<!--                    </div>-->
<!--                    <div class="nav-item">-->
<!--                        <span :class="{active: active === 2}"><a href="#buy" @click="active = 2">{{$t('header3')}}</a></span>-->
<!--                    </div>-->
<!--                    <div class="nav-item">-->
<!--                        <span :class="{active: active === 3}"><a href="#roadmap" @click="active = 3">{{$t('header4')}}</a></span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="right">
<!--                <span>-->
<!--                    <img src="../../assets/images/pc/locale.png" />-->
<!--                </span>-->
                <div class="nav">
                    <div class="button" :class="{active: visible}" @click="visible = !visible">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div class="nav-content" :class="{active: visible}" @click="visible = false">
                        <div class="nav-list">
                            <div class="nav-item">
                                <span :class="{active: active === 0}"><a href="#bolwup" @click="active = 0">{{$t('header1')}}</a></span>
                            </div>
                            <div class="nav-item">
                                <span :class="{active: active === 1}"><a href="#tokenomics" @click="active = 1">{{$t('header2')}}</a></span>
                            </div>
                            <div class="nav-item">
                                <span :class="{active: active === 2}"><a href="#buy" @click="active = 2">{{$t('header3')}}</a></span>
                            </div>
                            <div class="nav-item">
                                <span :class="{active: active === 3}"><a href="#roadmap" @click="active = 3">{{$t('header4')}}</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'mobileHeaderComp',
    data() {
        return {
            active: -1,
            visible: false
        }
    },
    watch: {
    },
    computed: {
    },
    methods: {
    }
}
</script>

<style lang="less">
.mobile-header {
    width: 100%;
    background: #D0423F;
    height: 4rem;
    padding: 0.2rem 0;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 999;
    .header-content {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        .logo {
            margin-left: 1rem;
            img {
                width: 132px;
                height: 46.5px;
            }
        }
        .content {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }
        .right {
            width: 3.2rem;
            display: flex;
            align-items: center;
            .nav {
                position: relative;
                .button {
                    display: flex;
                    flex-direction: column;
                    width: 2.4rem;
                    height: 2rem;
                    padding: 0.1rem 0.2rem;
                    border-radius: 3px;
                    &.active {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                    span {
                        background-color: #fff;
                        flex: 1;
                        margin: 0.2rem 0;
                        border-radius: 0.15rem;
                    }
                }
                .nav-content {
                    position: fixed;
                    top: 0;
                    right: 0;
                    width: 100vw;
                    height: 100vh;
                    visibility: hidden;
                    &.active {
                        visibility: visible;
                        .nav-list {
                            height: 10.8rem;
                        }
                    }
                    .nav-list {
                        position: absolute;
                        right: 0;
                        top: 4rem;
                        background-color: #D0423F;
                        width: 100vw;
                        border-top: solid 1px #fff;
                        height: 0;
                        overflow: hidden;
                        transition: height .6s;
                        padding: 0.6rem 0;
                        .nav-item {
                            height: 2.4rem;
                            display: flex;
                            span {
                                flex: 1;
                                padding: 0.3rem 1rem;
                                a {
                                    color: #fff;
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
