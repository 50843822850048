<template>
    <div class="pc-footer">
        <div class="footer-content">
            <div class="top" v-html="$t('footer_1')"></div>
            <div class="content">
                <div class="right">
                    <div class="text">{{$t('footer_3')}}</div>
                    <div class="buttons">
                        <a target="_blank" :href="cpd_wp">{{$t('footer_4')}}</a>
                        <a target="_blank" href="https://bscscan.com/token/0x8b4F96981e79d633A796Dc06672A9928CCE5aBA7">BscScan</a>
                    </div>
                </div>
                <div class="left">
                    <div class="text">{{$t('footer_2')}}</div>
                    <div class="share">
                        <a target="_blank" href="https://www.facebook.com/blowupcoin"><i class="icon-1"></i></a>
                        <a target="_blank" href="https://twitter.com/blowupcoin?s=21"><i class="icon-2"></i></a>
                        <a target="_blank" href="https://t.me/blowupcoin"><i class="icon-3"></i></a>
                        <a target="_blank" href="https://www.instagram.com/blowupcoin"><i class="icon-4"></i></a>
                        <a target="_blank" href="https://www.reddit.com/u/blowupcoin/"><i class="icon-5"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PcFooter',
    data() {
        return {
        }
    },
    computed: {
        cpd_wp() {
            return `http://www.blowupcoin.com/files/white_paper_eng.pdf?${new Date().getTime()}`
        }
    },
    methods: {
    }
}
</script>

<style lang="less">
.pc-footer {
    width: 100%;
    overflow: hidden;
    .footer-content {
        width: 100%;
        background-color: #D0423F;
        background-image: url("../../assets/images/pc/footer.png");
        background-size: 180% auto;
        background-position: center 16.6rem;
        background-repeat: repeat-x;
        padding: 0 0;
    }
    .top {
        padding: 1.2rem 0;
        text-align: center;
        color: #fff;
        font-size: 16px;
        border-bottom: solid 2px #fff;
        margin: 0 0.6rem;
    }
    .content {
        margin: 0 0.6rem;
        display: flex;
        flex-direction: column;
        padding-bottom: 8rem;
        .left {
            display: flex;
            flex-direction: column;
            padding: 1rem 0;
            .text {
                color: #fff;
                font-size: 16px;
                letter-spacing: -0.05rem;
                text-align: center;
            }
            .share {
                display: flex;
                padding: 12px 0 0 0;
                justify-content: center;
                a {
                    display: flex;
                    margin-right: 16px;
                    i {
                        width: 23.8px;
                        height: 24.5px;
                        background-size: 100%;
                        background-repeat: no-repeat;
                        &.icon-1 {
                            background-image: url("../../assets/images/pc/footer-1.png");
                        }
                        &.icon-2 {
                            background-image: url("../../assets/images/pc/footer-2.png");
                        }
                        &.icon-3 {
                            background-image: url("../../assets/images/pc/footer-3.png");
                        }
                        &.icon-4 {
                            background-image: url("../../assets/images/pc/footer-4.png");
                        }
                        &.icon-5 {
                            background-image: url("../../assets/images/pc/footer-5.png");
                        }
                    }
                }
            }
        }
        .right {
            padding-top: 0.6rem;
            padding-bottom: 1rem;
            border-bottom: solid 2px #fff;
            .text {
                color: #fff;
                text-align: center;
            }
            .buttons {
                display: flex;
                padding-top: 12px;
                justify-content: center;
                a {
                    color: #D0423F;
                    line-height: 28px;
                    background-color: #ffffff;
                    padding: 0 24px;
                    margin: 0 1rem;
                    border-radius: 5px;
                    font-family: ChalkboardSE-light;
                    font-size: 16px;
                }
            }
        }
    }

}
</style>
